
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('farm_config.allocation_list') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <!-- fiscal year id -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('globalTrans.fiscal_year')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  id="fiscal_year_id"
                                  :options="fiscalYearList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- season -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="season_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('farm_config.season')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.season_id"
                                  id="season_id"
                                  :options="seasonList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- circular -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('farm_config.circular')}}
                              </template>
                              <b-form-select
                                  plain
                                  :disabled="circularLoading ? true : false"
                                  v-model="search.circular_id"
                                  id="circular_id"
                                  :options="circularList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                               <template v-slot:first>
                                      <b-form-select-option :value="0">{{circularLoading ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- district -->
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="district_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{$t('globalTrans.district')}}
                              </template>
                              <b-form-select
                                  :disabled="id ? true : false"
                                  plain
                                  v-model="search.district_id"
                                  id="district_id"
                                  :options="districtList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('farm_config.allocation_list') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link class="btn btn-sm btn-primary" :to="{name: 'incentive_grant_service.farm_machinery.allocation.allocation_entry'}" variant="primary btn_add_new">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </router-link>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-danger" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                                        <span class="badge badge-primary" v-else-if="data.item.status == 2">{{ currentLocale === 'en' ? 'Pending' : 'প্রক্রিয়াধীন' }}</span>
                                        <span class="badge badge-success" v-else-if="data.item.status == 3">{{$t('globalTrans.approved')}}</span>
                                    </template>
                                    <template v-slot:cell(mobile_no)="data">
                                      {{ ($i18n.locale == 'bn' ? '০': '0') + $n(data.item.mobile_no, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(fiscal_year_id)="data">
                                    {{ getFiscalYearName(data.item.fiscal_year_id)}}
                                  </template>
                                  <template v-slot:cell(season_id)="data">
                                    {{ getSeasonName(data.item.season_id)}}
                                  </template>
                                  <template v-slot:cell(district_id)="data">
                                    {{ getDistrictName(data.item.district_id)}}
                                  </template>
                                  <template v-slot:cell(circular_id)="data">
                                    <span v-if="data.item.circular">{{ currentLocale === 'en' ? data.item.circular.circular_name : data.item.circular.circular_name_bn}}</span>
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <a href="javascript:" class="btn_table_action table_action_view" title="Farmer List" v-b-modal.modal-6 size="sm" @click="details(data.item)">
                                        <i class="fa fa-users"></i>
                                      </a>
                                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                        <i class="fas fa-eye"></i>
                                      </a>
                                      <router-link v-if="data.item.status === 1" :to="{name: 'incentive_grant_service.farm_machinery.allocation.allocation_entry', query: {id: data.item.id, from: 'incentive_grant_service.farm_machinery.allocation.allocation_list'} }" class="btn_table_action table_action_edit" title="Edit" size="sm">
                                          <i class="ri-ball-pen-fill"></i>
                                      </router-link>
                                  </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="xl" :title="$t('farm_config.allocation') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-6" size="xl" :title="this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <FarmerDetails :upazilaData="item" source="1" :key="item"></FarmerDetails>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import FarmerDetails from './FarmerDetails'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmAllocationList, farmMachineryToggleStatus, circularListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details,
    FarmerDetails
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        district_id: 0
      },
      circularList: [],
      circularLoading: false,
      rows: [],
      item: ''
    }
  },
  mounted () {
    this.getCircularList()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('farm_config.supplier_info') + ' ' + this.$t('globalTrans.add') : this.$t('farm_config.supplier_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('farm_config.season'), class: 'text-center' },
          { label: this.$t('farm_config.circular'), class: 'text-center' },
          { label: this.$t('globalTrans.district'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'season_id' },
          { key: 'circular_id' },
          { key: 'district_id' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_id' },
          { key: 'season_id' },
          { key: 'circular_id' },
          { key: 'district_id' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    seasonList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
      ]
    },
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    }
  },
  watch: {
      loadingState: function (newVal, oldVal) {
        if (newVal) {
          this.loadData()
        }
      },
      'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCircularList()
        }
      },
      'search.season_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCircularList()
        }
      },
      currentLocale: function (newVal, oldVal) {
        this.getCircularList()
      }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, farmMachineryToggleStatus, item, 'incentive_grant')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, farmAllocationList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    async getCircularList () {
      this.circularLoading = true
      const params = {
        fiscal_year_id: this.search.fiscal_year_id,
        season_id: this.search.season_id,
        lang: this.currentLocale
      }
      let result = null
      result = await RestApi.getData(incentiveGrantServiceBaseUrl, circularListApi, params)
      if (result.success) {
          this.circularList = result.data
      } else {
          this.circularList = []
      }
      this.circularLoading = false
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSeasonName (id) {
      const obj = this.seasonList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDistrictName (id) {
      const obj = this.$store.state.commonObj.districtList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
